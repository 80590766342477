import DashboardLayout from "@/pages/layouts/DashboardLayout";

let questionsRoutes = {
  path: '/questions',
  component: DashboardLayout,
  redirect: '/questions/list',
  children: [
    {
      path: 'list',
      name: 'Questions List',
      component: () => import(/* webpackChunkName: "questions" */  '@/pages/questions/QuestionList'),
      meta: {
        permissionCode: 'questions/index',
      }
    },
    {
      path: 'create',
      name: 'Add Question',
      component: () => import(/* webpackChunkName: "questions" */  '@/pages/questions/QuestionForm'),
      meta: {
        permissionCode: 'questions/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Question',
      component: () => import(/* webpackChunkName: "questions" */  '@/pages/questions/QuestionForm'),
      meta: {
        permissionCode: 'questions/update',
      }
    }
  ]
}

export default questionsRoutes;
