import DashboardLayout from "@/pages/layouts/DashboardLayout";

let academyRoutes = {
  path: '/academies',
  component: DashboardLayout,
  redirect: '/academies/list',
  children: [
    {
      path: 'list',
      name: 'Academy List',
      component: () => import(/* webpackChunkName: "academies" */  '@/pages/academies/AcademyList'),
      meta: {
        permissionCode: 'academies/index',
      }
    },
    {
      path: 'create',
      name: 'Add Academy',
      component: () => import(/* webpackChunkName: "academies" */  '@/pages/academies/AcademyForm'),
      meta: {
        permissionCode: 'academies/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Academy',
      component: () => import(/* webpackChunkName: "academies" */  '@/pages/academies/AcademyForm'),
      meta: {
        permissionCode: 'academies/update',
      }
    }
  ]
}

export default academyRoutes;
