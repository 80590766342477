import DashboardLayout from "@/pages/layouts/DashboardLayout";

let clientRoutes = {
  path: '/clients',
  component: DashboardLayout,
  redirect: '/clients/list',
  children: [
    {
      path: 'list',
      name: 'Client List',
      component: () => import(/* webpackChunkName: "clients" */  '@/pages/clients/ClientList'),
      meta: {
        permissionCode: 'clients/index',
      }
    },
    {
      path: 'get/:id',
      name: 'Client View',
      component: () => import(/* webpackChunkName: "clients" */  '@/pages/clients/ClientView'),
      meta: {
        permissionCode: 'clients/get',
      }
    },
  ]
}

export default clientRoutes;
