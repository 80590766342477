import DashboardLayout from "@/pages/layouts/DashboardLayout";

let teamMembersRoutes = {
  path: '/team-members',
  component: DashboardLayout,
  redirect: '/team-members/list',
  children: [
    {
      path: 'list',
      name: 'Team Members List',
      component: () => import(/* webpackChunkName: "team-members" */  '@/pages/teamMembers/TeamMemberList'),
      meta: {
        permissionCode: 'team-members/index',
      }
    },
    {
      path: 'create',
      name: 'Add Member',
      component: () => import(/* webpackChunkName: "team-members" */  '@/pages/teamMembers/TeamMemberForm'),
      meta: {
        permissionCode: 'team-members/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Member',
      component: () => import(/* webpackChunkName: "team-members" */  '@/pages/teamMembers/TeamMemberForm'),
      meta: {
        permissionCode: 'team-members/update',
      }
    }
  ]
}

export default teamMembersRoutes;
