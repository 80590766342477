import DashboardLayout from "@/pages/layouts/DashboardLayout";

let studioRoutes = {
  path: '/studios',
  component: DashboardLayout,
  redirect: '/studios/list',
  children: [
    {
      path: 'list',
      name: 'Studio List',
      component: () => import(/* webpackChunkName: "studios" */  '@/pages/studios/StudioList'),
      meta: {
        permissionCode: 'studios/index',
      }
    },
    {
      path: 'create',
      name: 'Add Studio',
      component: () => import(/* webpackChunkName: "studios" */  '@/pages/studios/StudioForm'),
      meta: {
        permissionCode: 'studios/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Studio',
      component: () => import(/* webpackChunkName: "studios" */  '@/pages/studios/StudioForm'),
      meta: {
        permissionCode: 'studios/update',
      }
    }
  ]
}

export default studioRoutes;
