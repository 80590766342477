<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">
    <div v-if="contactUsObject" style="text-align: center" v-html="contactUsObject.message"></div>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
            </span>
  </el-dialog>
</template>

<script>
import {Dialog} from 'element-ui'

export default {
  name: 'contact-us-message-modal',

  components: {
    [Dialog.name]: Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    contactUsObject: {
      required: true
    },
  },

  methods: {

    closeModal() {
      this.$emit('close');
    },
  }

}
</script>
