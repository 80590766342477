import DashboardLayout from "@/pages/layouts/DashboardLayout";

let partnerRoutes = {
  path: '/partners',
  component: DashboardLayout,
  redirect: '/partners/list',
  children: [
    {
      path: 'list',
      name: 'Partners List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/partners/PartnerList'),
      meta: {
        permissionCode: 'partners/index',
      }
    },
    {
      path: 'create',
      name: 'Add Partners',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/partners/PartnerForm'),
      meta: {
        permissionCode: 'partners/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Partners',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/partners/PartnerForm'),
      meta: {
        permissionCode: 'partners/update',
      }
    }
  ]
}

export default partnerRoutes;
