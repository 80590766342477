<template>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar>

      <user-menu></user-menu>

      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('clients/index')"
                      :link="{name: 'Clients', icon: 'nc-icon nc-badge', path: '/clients/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('services/index')"
                      :link="{name: 'Services', icon: 'nc-icon nc-bullet-list-67', path: '/services/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('academies/index')"
                      :link="{name: 'Academies', icon: 'nc-icon nc-puzzle-10', path: '/academies/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('studios/index')"
                      :link="{name: 'Studios', icon: 'nc-icon nc-tv-2', path: '/studios/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('partners/index')"
                      :link="{name: 'Partners', icon: 'nc-icon nc-badge', path: '/partners/list'}">
        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/checkAccess']('team-members/index')"
                      :link="{name: 'Team Members', icon: 'nc-icon nc-single-02',path: '/team-members/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('questions/index')"
                      :link="{name: 'Questions', path: '/questions/list', icon: 'nc-icon nc-bulb-63'}">

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('newsletter/index')" :link="{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/checkAccess']('contact-us/index')" :link="{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/checkAccess']('pages/index')
                            || $store.getters['auth/checkAccess']('content-pages/create')
                            || $store.getters['auth/checkAccess']('redirection-pages/create')
                            || $store.getters['auth/checkAccess']('branches/index')"
                      :link="{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}">

          <sidebar-item v-if="$store.getters['auth/checkAccess']('pages/index')"
                        :link="{name: 'Existing Pages', path: '/pages/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('content-pages/create')"
                        :link="{name: 'Add Content Page', path: '/content-pages/create'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('redirection-pages/create')"
                        :link="{name: 'Add Redirection Page', path: '/redirection-pages/create'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('branches/index')"
                        :link="{name: 'Branches', path: '/branches/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('panel-groups/index')
                            || $store.getters['auth/checkAccess']('panels/index')"
                      :link="{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}">

          <sidebar-item v-if="$store.getters['auth/checkAccess']('panels/index')"
                        :link="{name: 'Panels', path: '/panels/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('panel-groups/index')"
                        :link="{name: 'Groups', path: '/panel-groups/list'}"></sidebar-item>

        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/checkAccess']('settings/index')
                  || $store.getters['auth/checkAccess']('mediaInfo/index')"
            :link="{name: 'Settings', icon: 'nc-icon nc-circle-09'}">

            <sidebar-item v-if="$store.getters['auth/checkAccess']('mediaInfo/index')"
                          :link="{name: 'Media Info', path: '/mediaInfo/list'}">
            </sidebar-item>
            <sidebar-item v-if="$store.getters['auth/checkAccess']('settings/index')"
                          :link="{name: 'Settings', path: '/settings/list'}">
            </sidebar-item>


        </sidebar-item>


        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')
                            || $store.getters['auth/checkAccess']('permissions/index')
                            || $store.getters['auth/checkAccess']('users/index')"
                      :link="{name: 'Access Management', icon: 'nc-icon nc-circle-09'}">

          <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')"
                        :link="{name: 'Roles', path: '/roles/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('permissions/index')"
                        :link="{name: 'Permissions', path: '/permissions/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/index')"
                        :link="{name: 'Users', path: '/users/list'}"></sidebar-item>

        </sidebar-item>





      </template>

    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'


export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}

</script>
