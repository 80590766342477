import DashboardLayout from "@/pages/layouts/DashboardLayout";

let serviceRoutes = {
  path: '/services',
  component: DashboardLayout,
  redirect: '/services/list',
  children: [
    {
      path: 'list',
      name: 'services List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/services/ServiceList'),
      meta: {
        permissionCode: 'services/index',
      }
    },
    {
      path: 'create',
      name: 'Add Service',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/services/ServiceForm'),
      meta: {
        permissionCode: 'services/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Service',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/services/ServiceForm'),
      meta: {
        permissionCode: 'services/update',
      }
    }
  ]
}

export default serviceRoutes;
